import React from "react";
import Slider from "react-slick";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const OurAchivementNew = (props) => {
  const settings = {
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    // cssEase: "linear",
    // pauseOnHover: true,
    arrows: false,
    // centerMode: true,

    className:
      "flex justify-center flex-row max-w-3xl bg-white mx-auto rounded-md shadow-lg about-slider",

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplaySpeed: 3500,
          autoplay: true,
          // dots: true,
          // centerPadding: "300px",
          // className: "centered-slider",
          className:
            "flex justify-center flex-row max-w-3xl bg-white mx-auto rounded-md shadow-lg about-slider",
        },
      },

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          autoplaySpeed: 3500,
          autoplay: true,
          className:
            "flex justify-center flex-row max-w-3xl bg-white mx-auto rounded-md shadow-lg about-slider",
          // centerPadding: "90px",
          // className: "centered-slider",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: false,
          infinite: true,
          autoplaySpeed: 3500,
          autoplay: true,
          className:
            "flex justify-center flex-row max-w-3xl bg-white mx-auto rounded-md shadow-lg ",
          // centerPadding: "60px",
          // className: "centered-slider",
        },
      },
    ],
  };
  return (
    <div className="bg-gray-100 z-10">
      <div className="mx-4 lg:mx-36 py-10 lg:py-20">
        <div className="text-center lg:text-5xl text-3xl font-bold text-secondary-darkpurple-500">
          {props.about_us_achivement_title_highligted}
        </div>
        <div className=" text-center text-lg font-semibold mt-12">
          {props.about_us_achivement_desc[0].children[0].text}
          <br />
          {props.about_us_achivement_desc[1].children[0].text}
        </div>
        <div className="mt-10">
          <Slider {...settings}>
            {props.about_us_achivement_info_section.map((curr) => {
              return (
                <>
                  <div className="flex">
                  <div className="flex-1">
                    <GatsbyImageCustom
                      id={curr.about_us_belief_image.asset._ref}
                      alt={curr.about_us_belief_image_alt}
                      className="w-full h-full"
                    />
                  </div>
                  <div className="flex-1 flex flex-col justify-center items-center rounded p-8">
                    {/* <div className=""> */}
                    <div className="pb-8 px-7 text-center text-xl font-bold text-secondary-darkpurple-500 uppercase">
                      {curr.about_us_achivement_title_highligted}
                    </div>
                    <div className=" px-10 text-center text-lg font-normal italic">
                      {curr.about_us_achivement_desc}
                    </div>
                  </div>
                  </div>
                </>
              );
            })}
            {/* <div className="flex justify-center flex-row items-center max-w-3xl mx-auto bg-white rounded-md"> */}
            {/* <div className="flex-1">
              <GatsbyImageCustom
                id={
                  props.about_us_achivement_info_section[0]
                    .about_us_belief_image.asset._ref
                }
                alt={
                  props.about_us_achivement_info_section[0]
                    .about_us_belief_image_alt
                }
                className="w-full h-full"
              />
            </div>
            <div className="flex-1  rounded ">
              <div className="  flex flex-col justify-center ">
                <div className="pt-10 px-7 text-center text-xl font-bold text-secondary-darkpurple-500 uppercase">
                  {
                    props.about_us_achivement_info_section[1]
                      .about_us_achivement_title_highligted
                  }
                </div>
                <div className="pt-7 px-10 text-center text-lg font-normal italic">
                  {
                    props.about_us_achivement_info_section[1]
                      .about_us_achivement_desc
                  }
                </div>
              </div>
            </div> */}
            {/* </div> */}
          </Slider>
        </div>
        {/* <div className="grid mt-8 place-items-center gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 lg:grid-rows-3 xl:grid-rows-2 xl:grid-flow-col">
          <div className=""></div>

          <div className="pt-8 lg:pt-0 lg:hidden block">
            <GatsbyImageCustom
              id={
                props.about_us_achivement_info_section[2].about_us_belief_image
                  .asset._ref
              }
              alt={
                props.about_us_achivement_info_section[2]
                  .about_us_belief_image_alt
              }
              className="w-80 h-72"
            />
          </div>
          <div className="pt-8 lg:pt-0">
            <div className="w-80 h-72 bg-white rounded flex flex-col justify-center ">
              <div className="pt-10 px-7 text-center text-xl font-bold text-secondary-darkpurple-500 uppercase">
                {
                  props.about_us_achivement_info_section[0]
                    .about_us_achivement_title_highligted
                }
              </div>
              <div className="pt-7 px-10 text-center text-lg font-normal italic">
                {
                  props.about_us_achivement_info_section[0]
                    .about_us_achivement_desc
                }
              </div>
            </div>
          </div>
          <div className="pt-8 lg:pt-0 hidden lg:block">
            <GatsbyImageCustom
              id={
                props.about_us_achivement_info_section[2].about_us_belief_image
                  .asset._ref
              }
              alt={
                props.about_us_achivement_info_section[2]
                  .about_us_belief_image_alt
              }
              className="w-80 h-72"
            />
          </div>

          <div className="pt-8 lg:pt-0">
            <GatsbyImageCustom
              id={
                props.about_us_achivement_info_section[1].about_us_belief_image
                  .asset._ref
              }
              alt={
                props.about_us_achivement_info_section[1]
                  .about_us_belief_image_alt
              }
              className="w-80 h-72"
            />
          </div>
          <div className="pt-8 lg:pt-0">
            <div className="w-80 h-72 bg-white rounded flex flex-col justify-center ">
              <div className="pt-10 px-7 text-center text-xl font-bold text-secondary-darkpurple-500 uppercase">
                {
                  props.about_us_achivement_info_section[2]
                    .about_us_achivement_title_highligted
                }
              </div>
              <div className="pt-7 px-10 text-center text-lg font-normal italic">
                {
                  props.about_us_achivement_info_section[2]
                    .about_us_achivement_desc
                }
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurAchivementNew;
