import React from "react";
import Img from "../../assets/images/team.jpg";

const WhoWeAreNew = (props) => {
  return (
    <div className="bg-white">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-center items-center flex-col lg:flex-row px-6 lg:py-36 py-10 z-10">
          <div className="flex-1 max-w-md">
            <div className="text-center lg:text-left lg:text-5xl text-3xl text-secondary-darkpurple-500 font-bold mb-4 lg:mb-12">
              {props.about_us_info_title_highligted}
            </div>
            <div className="flex justify-center">
              <p className="text-left lg:text-base text-sm mb-2">
                {props.about_us_info_desc}
                {/* Amethhyyst continues to be in the center of Mumbai's population.
                Amethhyyst - The largest VIP lounge in Mumbai, featuring fashion
                shows and all the glitter and glam of the city in one spot. A
                place where you can chill & hangout with your friends, party,
                and experience the glamour of the fashion world. */}
              </p>
            </div>
            {/* <div className="flex justify-center">
              <p className="text-left lg:text-base text-sm">
                {props.about_us_info_desc}
                The Main Floor can accommodate a lot of guests and host a
                variety of events ranging from corporate to private. Our live
                event will be a highlight of your unforgettable experience, from
                finest DJs to a changing cast of amazing local musicians.
                Amethhyyst’s grand entrance, with matrix lights and cutting-edge
                audio and visual technologies, accomplishes the aesthetic.
              </p>
            </div> */}
          </div>
          <div className="flex-1 pl-0 pt-8 lg:pt-0 lg:pl-24 ">
            <img 
            src={Img} 
            // src="http://via.placeholder.com/576x324"
            alt="team" 
            className="shadow-1xl rounded-md"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAreNew;
