import { Link } from "gatsby";
import moment from "moment";
import React from "react";
import Bg_img from "../../assets/images/events_listing_banner.png";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const RecentNewsCardComponent = (props) => {
  // console.log(props, "fgh");
  let arr = props.press_and_media_feilds_section;
  const sortByDate = (arr) => {
    const sorter = (a, b) => {
      return new Date(b.news_date) - new Date(a.news_date);
    };
    arr.sort(sorter);
  };
  sortByDate(arr);
  // console.log(arr);
  return (
    <>
      <div className="relative z-10">
        <img
          style={{ height: "500px" }}
          src={Bg_img}
          alt="img header"
          className="w-full object-cover filter brightness-50"
        />
        <div className="mx-auto max-w-6xl">
          <div className=" absolute left-0 right-0 top-0 bottom-0 m-auto flex justify-center items-center">
            <div className="px-4">
              <div className="font-bold lg:text-6xl md:text-4xl  text-2xl text-white text-center">
                Press & Media
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-6xl py-20 px-4 lg:px-10">
        <div className="font-bold text-4xl pb-12">Recent News</div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {arr
            .filter((aa, index) => index < 6)
            .map((aa, idx) => {
              return (
                <div key={idx}>
                  {/* {} */}
                  <Link to={aa.news_link}>
                    <div className="flex flex-col shadow-lg hover:shadow-1xl  cursor-pointer">
                      <div>
                        <GatsbyImageCustom
                          id={aa.news_thumbnail.asset._ref}
                          // className="object-contain w-full h-52"

                          classes="recent-news-image"
                        />
                      </div>
                      <div className="h-40">
                        <div className="bg-gray-100 p-5 min-h-full">
                          <div className="flex text-sm justify-center font-bold text-center pt-5 max-h-16 my-auto h-full">
                            {aa.news_title[0].children[0].text}
                          </div>
                          <div className="flex text-xs justify-between pt-8">
                            <div>{aa.news_author}</div>
                            <div className="flex justify-center text-xs">
                              {moment(aa.news_date).format("MMMM DD, YYYY")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>

      <div className="mx-auto max-w-6xl py-20">
        {arr
          .filter((aa, index) => index > 6)
          .map((aa, idx) => {
            return (
              <div
                key={idx}
                className="flex flex-col md:flex-row justify-center items-center lg:justify-between p-8 border-b-4"
              >
                <div className="pr-0 lg:pr-10">
                  <div className="h-32 w-32">
                    <GatsbyImageCustom
                      id={aa.news_thumbnail.asset._ref}
                      className="object-cover w-full h-full"
                    />
                  </div>
                </div>
                <div className="w-auto lg:w-576">
                  <div className="flex flex-col min-w-full">
                    <div className="text-center lg:text-left text-xs pt-2 lg:pt-0">
                      {moment(aa.news_date).format("MMMM DD, YYYY")}
                    </div>
                    <div className=" text-center text-sm font-bold lg:text-left pt-5 max-h-16 my-auto h-full">
                      {aa.news_title[0].children[0].text}
                    </div>
                    <div className="text-center lg:text-left text-xs pt-8 pb-2 lg:pb-0">
                      {aa.news_author}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <Link to={aa.news_link} target="_blank">
                    <button className="w-40 custom-btn">Read More</button>
                  </Link>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default RecentNewsCardComponent;
