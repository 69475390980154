import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faHome,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import axios from "contentful-management/node_modules/axios";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import moment from "moment";
import React, { useState } from "react";
// import { FacebookIcon, FacebookShareButton } from "react-share";
import Loader from "../../pages/loader";

const ContactFormComponent = (props) => {
  const [loading, setLoading] = useState(false);
  // console.log(props.location, "props");
  const LocationData = [
    {
      icon: <FontAwesomeIcon icon={faPhoneAlt} size="1x" className="mt-2" />,
      title: "Phone",
      desc: "+91 9320793207",
    },
    {
      icon: <FontAwesomeIcon icon={faEnvelope} size="1x" className="mt-2" />,
      title: "Email",
      desc: "info@avgssgroup.com",
    },
    {
      icon: <FontAwesomeIcon icon={faHome} size="1x" className="mt-2" />,
      title: "Head Office",
      desc: "B 703 ONE BKC, G Block BKC, Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra 400051",
    },
  ];
  const initialValues = {
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    Email: "",
    Message: "",
  };

  const [login, setLogin] = useState(initialValues);

  const submitHandler = () => {
    let data = {
      FirstName: login.FirstName,
      LastName: login.LastName,
      PhoneNumber: login.PhoneNumber,
      Email: login.Email,
      Message: login.Message,
      CreateDTTM: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    // console.log(data, "login");
    fetch(
      "https://script.google.com/macros/s/AKfycbxYDQ_mRVu-bfX1_TJP4v3j19zXFG9umlLt4aXZPsPjYKLYOR1bap4_9qsA5YPBUICF/exec?action=addContactDetail",
      {
        method: "POST",
        body: JSON.stringify(data),
        mode: "cors",
      }
    )
      .then((response) => {
        // console.log(response);s
        if (response.status === 200) {
          setLoading(false);
          navigate(`/thank-you?form=${"contact"}`);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
    setLogin(initialValues);
  };
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="max-w-6xl mx-auto z-10">
            <div className="lg:px-24 md:px-10 px-6">
              <div className="lg:py-0 py-10">
                <div className="relative bottom-0 grid grid-cols-1 gap-14 sm:grid-cols-1 md:grid-cols-2 bg-secondary-gray-300 shadow-lg rounded px-10 py-20">
                  <div>
                    <div className="space-y-5 mt-5">
                      <div className="lg:flex block">
                        <div>
                          <span className="text-xl font-medium">Name</span>
                          <input
                            type="text"
                            className="form-input mt-2 text-sm p-5 border border-gray-300 lg:w-5/6 sm:w-6/6 w-full focus:outline-none rounded "
                            value={login.FirstName}
                            onChange={(e) =>
                              setLogin({ ...login, FirstName: e.target.value })
                            }
                            placeholder="FirstName"
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            className=" form-input  mt-9 text-sm p-5 border border-gray-300  lg:w-6/6 sm:w-full  w-full focus:outline-none rounded "
                            value={login.LastName}
                            onChange={(e) =>
                              setLogin({ ...login, LastName: e.target.value })
                            }
                            placeholder=" LastName"
                            required
                          />
                        </div>
                      </div>

                      <div>
                        <span className="text-xl font-medium ">
                          Phone Number
                        </span>
                        <input
                          type="number"
                          className=" form-input mt-2 text-sm p-5 border border-gray-300  lg:w-6/6 sm:w-full w-full focus:outline-none rounded "
                          value={login.PhoneNumber}
                          onChange={(e) =>
                            setLogin({ ...login, PhoneNumber: e.target.value })
                          }
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                      <div>
                        <span className="text-xl font-medium ">Email</span>
                        <input
                          type="text"
                          className=" form-input mt-2 text-sm p-5 border border-gray-300  lg:w-6/6 sm:w-full w-full focus:outline-none rounded "
                          value={login.Email}
                          onChange={(e) =>
                            setLogin({ ...login, Email: e.target.value })
                          }
                          placeholder="Email address"
                          required
                        />
                      </div>
                      <div>
                        <span className="text-xl font-medium ">Message</span>
                        <textarea
                          className="text-sm mt-2 p-5 border border-gray-300  lg:w-6/6 sm:w-full w-full focus:outline-none rounded "
                          value={login.Message}
                          onChange={(e) =>
                            setLogin({ ...login, Message: e.target.value })
                          }
                          placeholder="Enter Your Query...."
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="md:text-left text-center py-5">
                      <button
                        onClick={() => {
                          setLoading(true);
                          submitHandler();
                        }}
                        className="custom-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div>
                    {LocationData.map((xx, idx) => {
                      return (
                        <div key={idx} className="mb-10 mt-6">
                          <div className="flex ">
                            {xx.icon}
                            <p className="text-xl font-normal ml-6">
                              {xx.title}
                            </p>
                          </div>
                          <div className="ml-10 text-base font-normal">
                            {xx.desc}
                          </div>
                        </div>
                      );
                    })}
                    <div className="space-x-6">
                      {/* <FacebookShareButton url={props.location.href}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton> */}
                      <Link
                        to="https://www.facebook.com/amethhyyst/"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                      </Link>
                      <Link
                        to="https://www.instagram.com/avgssgroup/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContactFormComponent;
