import React from "react";
import BG from "../../assets/images/Coin_effect.png";
import CommomHeaderComponent from "../Common/CommomHeaderComponent";

const AmyHeaderComponent = (props) => {
  // console.log(props, "klj");
  return (
    <>
      <CommomHeaderComponent imgsrc={BG} />
      {/* <div className="relative bg-amyToken-img bg-cover">
        <img src={BG} />
      </div> */}
    </>
  );
};

export default AmyHeaderComponent;
