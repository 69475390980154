// import { faLine } from "@fortawesome/free-brands-svg-icons";
import {
  // faArrowAltCircleRight,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
// import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
// import {
//   faArrowCircleRight,
//   faArrowRight,
//   faDotCircle,
// } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const OurPhilosophyNew = (props) => {
  // console.log(props, "props");
  return (
    <div className="max-w-6xl mx-auto">
      <div className=" lg:py-36 py-10 z-10">
        <div className="text-center lg:text-5xl text-3xl text-secondary-darkpurple-500 font-bold pb-12">
          Our Group's Philosophy
        </div>
        <div className="flex justify-center items-center flex-col md:flex-row flex-wrap lg:flex-nowrap">
          {props?.philosophy_info_section &&
            props?.philosophy_info_section.map((aa, idx) => {
              return (
                <div className="pb-14 m-4 ">
                <div className="relative ">
                  <div className="featured-image featured-image-portfolio">
                    <div className="featured-thumbnail">
                      <div>
                        <div
                          className="flex justify-center items-center bg-white shadow-lg rounded-lg p-8 w-80 h-80"
                          key={idx}
                        >
                          <div>
                            <div className="flex justify-center items-center pb-4">
                              <GatsbyImageCustom id={aa.img?.asset?._ref} />
                            </div>
                            <div
                              style={{
                                flex: 1,
                                flexDirection: "column",
                                paddingLeft: 10,
                                justifyContent: "center",
                                textAlign: "center",
                                alignContent: "center",
                              }}
                            >
                              <div className="text-2xl lg:text-4xl text-center pb-3">
                                {aa.title}
                              </div>
                            </div>
                            <div className="text-base text-center pb-2">
                              {aa.desc[0].children[0].text}
                              {/* Vision is the art of seeing things invisible */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ttm-box-view">
                      <div className="ttm-media ">
                        <div className="ttm_prettyphoto ttm_image">
                          <div className="flex flex-col justify-center">
                            <div className="text-base text-left">
                              <ul className="list-outside">
                                {aa.desc[1] && (
                                  <li className="mb-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="lg"
                                      className="mr-2"
                                    />
                                    {aa.desc[1]?.children[0]?.text}
                                  </li>
                                )}
                                {aa.desc[2] && (
                                  <li className="mb-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="lg"
                                      className="mr-2"
                                    />
                                    {aa.desc[2]?.children[0]?.text}
                                  </li>
                                )}
                                {aa.desc[3] && (
                                  <li className="mb-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="lg"
                                      className="mr-2"
                                    />
                                    {aa.desc[3]?.children[0]?.text}
                                  </li>
                                )}
                                {aa.desc[4] && (
                                  <li className="mb-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="lg"
                                      className="mr-2"
                                    />
                                    {aa.desc[4]?.children[0]?.text}
                                  </li>
                                )}
                                {aa.desc[5] && (
                                  <li className="mb-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="lg"
                                      className="mr-2"
                                    />
                                    {aa.desc[5]?.children[0]?.text}
                                  </li>
                                )}
                                {aa.desc[6] && (
                                  <li className="mb-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="lg"
                                      className="mr-2"
                                    />
                                    {aa.desc[6]?.children[0]?.text}
                                  </li>
                                )}
                                {aa.desc[7] && (
                                  <li className="mb-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="lg"
                                      className="mr-2"
                                    />
                                    {aa.desc[7]?.children[0]?.text}
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default OurPhilosophyNew;
