import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const AmyDistributeComponent = (props) => {
  // console.log(props, "fgh");
  return (
    <>
      <div className="lg:px-24 px-6 pb-10  max-w-6xl mx-auto">
        <div className="py-20">
          <h1 className="text-center text-secondary-darkpurple-500  md:text-5xl text-2xl font-bold">
            {props.amy_token_distribute_title_highligted}
          </h1>
        </div>
        <div className=" grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2  ">
          <div>
            <GatsbyImageCustom
              id={props.amy_token_distribute_image.asset._ref}
              alt={props.amy_token_distribute_image_alt}
              className=" relative md:top-14 top-0 filter drop-shadow-2xl"
            />
          </div>
          <div className="space-y-6  text-xl font-bold ">
            {props.amy_token_distribute_info_section.map((aa, idx) => {
              return <p key={aa._key}>{aa.amy_token_distribute_title}</p>;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AmyDistributeComponent;
