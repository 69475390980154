import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";
// import Img from "../../assets/images/SushantJabare.png";
import Fade from "react-reveal";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const FounderHeader = (props) => {
  // console.log(props, "sad");
  return (
    <>
      <div className="pt-0 z-10">
        <div className=" bg-secondary-darkblue-500 bg-opacity-70 w-full">
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center">
              <div className="text-white p-4">
                <div className=" md:space-y-5 space-y-5">
                  <h1 className=" font-bold lg:text-3xl text-center text-xl text-secondary-golden-500">
                    - Meet
                  </h1>
                  <h1 className=" font-bold lg:text-5xl md:text-left text-center text-3xl text-secondary-golden-500">
                    {props.name}
                  </h1>
                  <h1 className=" w-auto lg:w-96 max-w-full mx-auto font-semibold text-center text-base text-secondary-golden-500">
                    {props.post} <br />
                    <span className="text-2xl font-normal font-Niconne">
                      {props.firm}
                    </span>
                  </h1>
                  <p className="max-w-xs mx-auto text-secondary-golden-500 italic">
                    {props.desc}
                  </p>

                  <div className="flex justify-center items-center text-secondary-golden-700">
                    <div className="pr-6">
                      <Link
                        to="https://www.facebook.com/sushantgjabareofficial/"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFacebookF} size="2x" />
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="https://www.instagram.com/sushantgjabare/?hl=en"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <Fade top>
                <div className="px-4">
                  <GatsbyImageCustom
                    id={props.founder_image.asset._ref}
                    alt="img"
                    // style={{objectFit:"contain"}}
                    className=" w-full lg:h-full"
                  />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FounderHeader;
