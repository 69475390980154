import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
// import WhoWeAre from "../AboutUs/WhoWeAre";
import Layout from "../Layout/Layout";
// import LeftImageRightText from "../AboutUs/LeftImageRightText";
// import OurStory from "../AboutUs/OurStory";
// import OurBeliefs from "../AboutUs/OurBeliefs";
// import OurAchivements from "../AboutUs/OurAchivements";
import AmyHeaderComponent from "../AmyToken/AmyHeaderComponent";
import AmyDetailComponent from "../AmyToken/AmyDetailComponent";
import CommomHeaderComponent from "../Common/CommomHeaderComponent";
// import FrequentAskQuestionComponent from "../AmyToken/FrequentAskQuestionComponent";
import AmyDistributeComponent from "../AmyToken/AmyDistributeComponent";
import ContactFormComponent from "../ContactUs/ContactFormComponent";
import RecentNewsCardComponent from "../PressAndNews/RecentNewsCardComponent";
import FounderHeader from "../Founder/FounderHeader";
import AmnthyystInfoSection from "../Home/AmnthyystInfoSection";
import MobileInfoSection from "../Home/MobileInfoSection";
// import OurPhilosophy from "../AboutUs/OurPhilosophy";
import OurDirectors from "../Common/OurDirectors";
import OurPhilosophyNew from "../AboutUs/OurPhilosophyNew";
import WhoWeAreNew from "../AboutUs/WhoWeAreNew";
import OurAchivementNew from "../AboutUs/OurAchivementNew";
// import OurCSR from "../Common/OurCSR";

const PageContent = ({ data, location }) => {
  // console.log("page Content data", location);

  return (
    <Layout location={location}>
      <Helmet>
        <title>{data.pagedata.meta_title || data.pagedata.title}</title>
        <meta
          property="og:title"
          content={data.pagedata.meta_title || data.pagedata.title}
        />
        <meta
          property="twitter:title"
          content={data.pagedata.meta_title || data.pagedata.title}
        />
        <meta name="description" content={data.pagedata.meta_desc} />
        <meta property="og:description" content={data.pagedata.meta_desc} />
        <meta
          property="twitter:description"
          content={data.pagedata.meta_desc}
        />

        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#144a95" />
        {/* {location.pathname === "/our-currency-new" ? (
          <>
            <meta content="noindex" />
            <meta content="nofollow" />
          </>
        ) : (
          <></>
        )} */}
      </Helmet>

      {/* {console.log(
        data.pagedata._rawPagebuildersection,
        "_rawPagebuildersection"
      )} */}
      {data.pagedata._rawPagebuildersection &&
        data.pagedata._rawPagebuildersection.map((section) => {
          // console.log(section, "page content");
          if (section._type === "common_banner_section") {
            return (
              <CommomHeaderComponent
                key={section._key}
                {...section}
                title={section.common_banner_title_highlighted}
                content={
                  section.common_banner_desc
                    ? section.common_banner_desc[0].children[0].text
                    : null
                }
                imgsrc={section.common_banner_image.asset._ref}
              />
            );
          } else if (section._type === "about_us_info_section") {
            // return <WhoWeAre key={section._key} {...section} />;
            return <WhoWeAreNew key={section._key} {...section} />;
          } else if (section._type === "philosophy_section") {
            // return <OurPhilosophy key={section._key} {...section} />;
            return <OurPhilosophyNew key={section._key} {...section} />;
          } else if (section._type === "directors_section") {
            return <OurDirectors key={section._key} {...section} />;
          }
          //  else if (section._type === "our_csr_section") {
          //   return <OurCSR key={section._key} {...section} />;
          // } 
          // else if (section._type === "about_us_team_section") {
          //   return <LeftImageRightText key={section._key} {...section} />;
          // }
          // else if (section._type === "about_us_storyline_section") {
          //   return <OurStory key={section._key} {...section} />;
          // }
          // else if (section._type === "about_us_belief_section") {
          //   return <OurBeliefs key={section._key} {...section} />;
          // } 
          else if (section._type === "about_us_achivement_section") {
            // return <OurAchivements key={section._key} {...section} />;
            return <OurAchivementNew key={section._key} {...section} />;
          } else if (section._type === "common_banner_section") {
            return <AmyHeaderComponent key={section._key} {...section} />;
          } else if (section._type === "amy_token_detail_section") {
            return <AmyDetailComponent key={section._key} {...section} />;
          } else if (section._type === "amy_token_distribute_section") {
            return <AmyDistributeComponent key={section._key} {...section} />;
          }
          // else if (section._type === "faq_section") {
          //   return (
          //     <FrequentAskQuestionComponent key={section._key} {...section} />
          //   );
          // }
          else if (section._type === "contact_form_section") {
            return (
              <ContactFormComponent
                key={section._key}
                {...section}
                location={location}
              />
            );
          } else if (section._type === "press_and_media_section") {
            return <RecentNewsCardComponent key={section._key} {...section} />;
          } else if (section._type === "founder_banner") {
            return <FounderHeader key={section._key} {...section} />;
          } else if (section._type === "amnthyyst_info_section") {
            return <AmnthyystInfoSection key={section._key} {...section} />;
          } else if (section._type === "home_mobile_info_section") {
            return <MobileInfoSection key={section._key} {...section} />;
          }
        })}
    </Layout>
  );
};

export const query = graphql`
  query GetRewardPage($slug: String) {
    pagedata: sanityPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      meta_title
      meta_desc
      id
      _rawPagebuildersection
    }
  }
`;
export default PageContent;
