import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const AmyDetailComponent = (props) => {
  // console.log(props, "sadf");
  return (
    <>
      <div className="lg:px-24 px-6 py-10  mx-auto max-w-6xl">
        <div className="py-10">
          <h1 className="text-center text-secondary-darkpurple-500  md:text-5xl text-2xl font-bold">
            {props.amy_token_detail_title_highligted}
          </h1>
        </div>

        <div className=" grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 ">
          <div className="space-y-6  text-xl font-bold pt-20">
            {props.amy_token_detail_info_section.map((aa, idx) => {
              return <p key={aa._key}>{aa.amy_token_detail_title}</p>;
            })}
          </div>
          <div>
            <GatsbyImageCustom
              id={props.amy_token_detail_image.asset._ref}
              alt={props.amy_token_detail_image_alt}
              // className=" relative md:left-44 left-0 filter drop-shadow-2xl "
              // className=" grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AmyDetailComponent;
