import React from "react";
import IMG1 from "../../assets/images/dollars.png";
import IMG2 from "../../assets/images/vip.png";
import IMG3 from "../../assets/images/events.png";
import IMG4 from "../../assets/images/foods.png";
import IMG5 from "../../assets/images/Mobile.png";

const MobileInfoSection = (props) => {
  // console.log(props);
  return (
    <>
      <div className="z-10 bg-secondary-gray-300 ">
        <div className="max-w-6xl mx-auto px-6 pb-20">
          <div className=" space-y-8 pt-16">
            <h1 className="lg:text-5xl text-2xl font-bold text-secondary-darkpurple-500 text-center">
              {props.mobile_info_highligted}
            </h1>

            <p className="lg:text-xl text-base text-left">
              {props.mobile_info_desc[0].children[0].text}
            </p>
            <p className="lg:text-xl text-base font-bold text-center">
              {props.mobile_info_desc[1].children[0].text}
              <br />
              <span className="font-normal text-center">
                {props.mobile_info_desc[2].children[0].text}
              </span>
            </p>
          </div>
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 pt-24">
            {/* 1 conatiner */}
            <div className="space-y-20 xl:mx-16 mx-6">
              <div className="space-y-5 text-center">
                <img src={IMG1} className="mx-auto" alt="img-1" />
                <h1 className="font-bold md:text-3xl text-xl">
                  {props.home_mobile_info_section[0].home_info_titles}
                </h1>
                <p>{props.home_mobile_info_section[0].home_info_descs}</p>
              </div>
              <div className="space-y-5 text-center">
                <img src={IMG3} className="mx-auto" alt="img-1" />
                <h1 className="font-bold md:text-3xl text-xl">
                  {props.home_mobile_info_section[2].home_info_titles}
                </h1>
                <p>{props.home_mobile_info_section[2].home_info_descs}</p>
              </div>
            </div>
            {/* 2 container */}
            <div className="flex justify-center items-center">
              <img
                src={IMG5}
                className=" relative lg:bottom-20 bottom-10 p-4 mx-auto"
                alt="img-1"
              />
            </div>
            {/* 3 container */}
            <div className="space-y-20 xl:mx-16 mx-6">
              <div className="space-y-5 text-center">
                <img src={IMG2} className="mx-auto" alt="img-1" />
                <h1 className="font-bold md:text-3xl text-xl">
                  {props.home_mobile_info_section[1].home_info_titles}
                </h1>
                <p>{props.home_mobile_info_section[1].home_info_descs}</p>
              </div>
              <div className="space-y-5 text-center">
                <img src={IMG4} className="mx-auto" alt="img-1" />
                <h1 className="font-bold md:text-3xl text-xl">
                  {props.home_mobile_info_section[3].home_info_titles}
                </h1>
                <p>{props.home_mobile_info_section[3].home_info_descs}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileInfoSection;
