import React from "react";

const AmnthyystInfoSection = (props) => {
  // console.log(props,"props")
  return (
    <>
      <div className="max-w-6xl mx-auto z-10">
        <div className="px-6 py-20 ">
          <div className=" text-center">
            <h1 className="  text-secondary-darkpurple-500 font-bold lg:text-5xl text-2xl text-center">
              {props.amnthyyst_info_highligted}
            </h1>
            <div className="space-y-7 pt-14">
              <p className="text-lg lg:text-xl font-medium text-justify">
                {props.amnthyyst_info_desc1}
              </p>
              <p className="text-lg lg:text-xl font-semibold text-justify">
                {props.amnthyyst_info_desc2}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmnthyystInfoSection;
