import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "gatsby";
// import img from "../../assets/images/avatar.png";
// import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const OurDirectors = (props) => {
  // console.log(props, "props");
  return (
    <div className="bg-white">
      <div className="max-w-6xl mx-auto lg:py-36 py-10 px-4 z-10">
        <div className="text-center lg:text-5xl text-3xl text-secondary-darkpurple-500 font-bold">
          {/* {props.title_highligted} */}
          Meet Our Leaders
        </div>
        <div
          className="p-6 lg:p-14 max-w-5xl mx-auto rounded-2xl my-10 shadow-lg bg-gray-100"
          // style={{ backgroundColor: "#ecf3f3" }}
        >
          <div className="flex flex-col lg:flex-row">
            {/* <div className=""> */}
            <GatsbyImageCustom
              id={props.directors_info_section[0]?.directors_image?.asset?._ref}
              alt="img"
              className=" w-72 h-80 lg:w-402 lg:h-430 lg:mr-10 mx-auto"
            />
            {/* </div> */}
            <div className="flex flex-col justify-center pt-4 lg:pt-0">
              <div className="text-base lg:text-lg text-center pb-4">
                {props.directors_info_section[0].directors_message}
              </div>
              <div className="text-sm flex text-secondary-gray-600">
                <span className="border-r-2 border-secondary-golden-500 hidden lg:block"></span>
                <div className="pl-0 lg:pl-6">
                  <div className="pb-2">
                    {
                      props.directors_info_section[0].directors_desc[0]
                        .children[0].text
                    }
                  </div>
                  <div>
                    {
                      props.directors_info_section[0].directors_desc[1]
                        .children[0].text
                    }
                  </div>
                </div>
              </div>
              <div className="text-lg pt-4 uppercase">
                {props.directors_info_section[0].directors_name}
              </div>
              <div className="text-base flex justify-between items-center uppercase">
                <div className="">
                  {props.directors_info_section[0].directors_post}
                </div>
                <div className="flex items-end">
                  <Link to="https://twitter.com/sushantgjabare">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      size="2x"
                      className="mr-3 text-secondary-darkpurple-500"
                    />
                  </Link>
                  <Link to="https://www.instagram.com/sushantgjabare/?igshid=YmMyMTA2M2Y%3D">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="2x"
                      className="mr-3 text-secondary-darkpurple-500"
                    />
                  </Link>
                  <Link to="https://www.facebook.com/sushantgjabareofficial/">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="2x"
                      className="mr-3 text-secondary-darkpurple-500"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-6 lg:p-14 max-w-5xl mx-auto rounded-2xl my-10 shadow-lg bg-gray-100"
          // style={{ backgroundColor: "#ecf3f3" }}
        >
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="flex flex-1 flex-col justify-center pt-4 lg:pt-0">
              <div className="text-base lg:text-lg text-center pb-4">
                {props.directors_info_section[1].directors_message}
              </div>
              <div className="text-sm flex text-secondary-gray-600">
                <div className="pr-0 lg:pr-6">
                  <div className="pb-2">
                    {
                      props.directors_info_section[1].directors_desc[0]
                        .children[0].text
                    }
                  </div>
                  <div className="pb-2">
                    {
                      props.directors_info_section[1].directors_desc[1]
                        .children[0].text
                    }
                  </div>
                  <div>
                    {
                      props.directors_info_section[1].directors_desc[2]
                        .children[0].text
                    }
                  </div>
                </div>
                <span className="border-r-2 border-secondary-golden-500 hidden lg:block"></span>
              </div>
              {/* <div className="flex flex-col justify-end"> */}
              <div className="text-lg pt-4 uppercase flex justify-start lg:justify-end">
                {props.directors_info_section[1].directors_name}
              </div>
              <div className="text-base flex flex-row-reverse lg:flex-row justify-between items-center uppercase">
                <div className="flex items-end">
                  <Link>
                    <FontAwesomeIcon
                      icon={faTwitter}
                      size="2x"
                      className="mr-3 text-secondary-darkpurple-500"
                    />
                  </Link>
                  <Link to="https://www.instagram.com/monnishpal/?igshid=YmMyMTA2M2Y%3D">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="2x"
                      className="mr-3 text-secondary-darkpurple-500"
                    />
                  </Link>
                  <Link to="https://www.facebook.com/monish.pal2">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="2x"
                      className="mr-3 text-secondary-darkpurple-500"
                    />
                  </Link>
                </div>
                <div className="">
                  {props.directors_info_section[1].directors_post}
                </div>
              </div>
              {/* </div> */}
            </div>
            <GatsbyImageCustom
              id={props.directors_info_section[1]?.directors_image?.asset?._ref}
              alt="img"
              className="mx-auto lg:ml-10 w-72 h-80 lg:w-402 lg:h-430"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurDirectors;
